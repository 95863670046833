import React from "react"
import Layout from "../../layouts/en"
import FormingToolsPageBase from "../../templates/wire"
import Intro from "../../components/intro"
import Link from "../../components/link"
import Cover from "../../components/cover"
import Illustration from "../../components/illustration"
import Features from "../../components/features"

export default () => {
    return (
        <Layout>
            <FormingToolsPageBase locale="en">
                <Intro title="Forming tools for the most challenging circumstances">
                    <p>Since 1963, we have offered our customers not only wire, but also wire forming tools. We produce and process precision parts for cold, semi-hot and hot forming in carbide and steel with passion. 
Prompt delivery and highest quality standards raise us out of the ordinary.</p>
                    <Link to="/forming-tools/delivery-program" className="accessory">To our delivery programme</Link>
                </Intro>
                <Cover eyebrow="Cutting-to-length" imageName="materialzuschnitt" title="Customised cutting">
                    <p>We carry out band saw cutting for normally-machinable solid material, pipes and profiles with automatic feed and cut accuracy of 0.1 mm per 100 mm cut height.</p>

                    <Features items={[
                        "Maximum cutting surface 500 mm x 400 mm or Ø 400 mm",
                        "Length: from 10 mm to 900 mm",
                    ]} />
                </Cover>
                <Cover eyebrow="Lathe centre" imageName="dreherei" title="For us, everything revolves around our customers" align="right">
                    <p>State-of-the-art machinery, high quality clamping tools and top class cutting tools allow us to fulfil all your requirements in steel and carbide.</p>
                    <p>We process a huge range of different carbide qualities and steels up to a hardness of 68 HRC. </p>

                    <Features items={[
                        "Diameter: up to Ø 250 mm",
                        "Length: up to 1,000 mm",
                    ]} />
                </Cover>
                <Cover eyebrow="Milling centre" imageName="fraserei" title="Shaping to absolute perfection">
                    <p>Via connection to constantly updated CAD/CAM software and the use of state-of-the-art optical tool measurement, we are able to achieve the highest surface qualities and the closest tolerances. This also applies for hardened steels (hardnesses up to 68 HRC) and carbides, and from volumes of one or more.</p>

                    <Features items={[
                        "600 x 500 x 500 mm"
                    ]} />
                </Cover>
                <Cover eyebrow="Grinding centre" imageName="schleiferei" title="We give tools the final edge" align="right">
                    <p>We process carbide, tool steel, high-speed steel and powder metallurgical steels with our numerous flat, circular and internal grinding machines and honing machines.</p>

                    <h4>Internal grinding working range</h4>
                    <Features items={[
                        "Diameter: from Ø 2 mm to 200 mm",
                        "Length: from 2 mm to 250 mm",
                    ]} />

                    <h4>External grinding working range</h4>
                    <Features items={[
                        "Diameter: up to Ø 250 mm",
                        "Length: up to 500 mm",
                    ]} />

                    <h4>Flat grinding working range</h4>
                    <Features items={[
                        "600 x 400 x 300 mm",
                        "Ø 600 mm",
                        
                    ]} />
                </Cover>
                <Cover eyebrow="Spark erosion" imageName="funkenerodiererei" title="When sparks fly">
                    <p>In conjunction with our CNC milling machines and precision measurement monitoring, we manufacture our own sink eroding tools, which we use on machines with a carbide generator. We are equally able to produce large conic sections on our wire eroding machines. This means maximum precision in geometries of any size or shape.</p>

                    <h4>Sink erosion working range</h4>
                    <Features items={[
                        "730 x 630 x 300 mm"
                    ]} />

                    <h4>Wire erosion working range</h4>
                    <Features items={[
                        "550 x 350 x 380 mm"
                    ]} />
                </Cover>
                <Cover eyebrow="Polishing centre" imageName="poliererei" title="We know the importance of finishing" align="right">
                    <p>The most important step for a long-lasting tool is carried out at Finkernagel in our own department of trained specialists. The last µ to the final dimension with Ra of up to 0.05 µm guarantees an optimum tool life.</p>
                </Cover>
                <Cover eyebrow="Quality" imageName="qualitat" title="Inspection from start to finish – and at all stages in between">
                    <p>In the climate-controlled quality test, we investigate at the µ-level with state-of-the-art measurement technology. We can carry out tactile measurement of even the deepest contours in tiny holes.</p>
                    <p>With measurement projectors and regularly calibrated manual measurement aids, we are equipped for all challenges.
If required, we resort to our extensive wireworks test equipment.</p>
                </Cover>

                <section className="section">
                    <header className="container">
                        <div className="col md:ml-auto md:mr-0 md:w-1/2 lg:w-1/3">
                            <h3 className="section-heading">The production process at a glance</h3>
                        </div>
                    </header>
                    <Illustration imageName="dreherei">
                        <h2 className="text-black">Lathe centre</h2>
                        <p>The blank is produced on a CNC lathe.</p>
                    </Illustration>
                    <Illustration imageName="fraeserei">
                        <h2 className="text-black">Milling centre</h2>
                        <p>Clamping surfaces and any necessary holes are added to the blank.</p>
                    </Illustration>
                    <Illustration imageName="schleiferei">
                        <h2 className="text-black">Grinding centre</h2>
                        <p>The hardened blank is ground to the final dimensions.</p>
                    </Illustration>
                    <Illustration imageName="zusammenbau">
                        <h2 className="text-black">Assembly</h2>
                        <p>The core is inserted into the blank.</p>
                    </Illustration>
                    <Illustration imageName="poliererei">
                        <h2 className="text-black">Polishing centre</h2>
                        <p>The internal contour is polished and the forming tool is finished.</p>
                    </Illustration>
                </section>
            </FormingToolsPageBase>
        </Layout>
    )
}
